import React from "react";
import moment from 'moment'

class FormationDateList extends React.Component {
  render() {
    const dates = this.props.dates;
    const datesIndex = []
    const validDates = []

    if (dates) {
      for (const [i, date] of dates.entries()) {
        if (moment().isBefore(date)) {
          validDates.push(date) 
          datesIndex.push(i) 
        }
      }
    }

    const sortedValidDates  = validDates.sort((a,b) => moment(a).diff(b))

    let datesContent;

    if (validDates.length === 0) {
      datesContent = <span>{this.props.noDatesMessage}</span>;
    } else {
      datesContent = <span>
                      {sortedValidDates.map((date, index) => {
                        const formatedDate = moment(date).format("DD/MM/YYYY");
                        return index === 0 ? 
                          <span key={index}>{formatedDate}</span> 
                          : 
                          <span key={index}>{` - ${formatedDate}`}</span>
                      })}
                    </span>;
    }

    return (
      <span>{datesContent}</span>
    )
  }
}

export default FormationDateList;